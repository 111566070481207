@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.overlay {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;
  background-color: color.$shadow;
  pointer-events: all;

  .window {
    @include mixins.border-radius;
    padding: size.$base;
    background-color: color.$white;
    box-shadow: utils.shadow();

    .modal {
      margin: size.$n-base;
      @include mixins.border-radius;
      position: relative;
      min-width: size.$modal-min-width;

      .headline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid utils.rem(1px) color.$secondary;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: font.size('big');

        span {
          padding-left: size.$base;
        }

        .controls {
          display: flex;
        }
      }
    }

    .body {
      max-width: size.$modal-min-width;
      padding: size.$half;

      .errors {
        padding: size.$half 0 size.$base 0;

        .error {
          padding: utils.rem(2px) 0;
          font-weight: bold;
          color: color.$error;
        }
      }
    }
  }
}
