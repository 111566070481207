@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';
@use '~/components/styles';

.header {
  display: flex;

  .title {
    @include mixins.w-full;
    padding: size.$base;
    @include styles.title;
  }

  .controls {
    display: flex;
    @include mixins.center('vertical');

    button {
      margin-right: size.$half;
      min-width: utils.rem(120px);
    }
  }
}

.content {
  width: size.$modal-min-width;

  .row {
    display: flex;
    margin: size.$base;

    .element:not(:last-child) {
      margin-right: size.$base;
    }

    .element {
      label {
        @include mixins.subtitle;
      }
    }

    .element_growing {
      flex: 100%;
      min-width: 0;
      @extend .element;
    }

    .image {
      @extend .element;
      margin-right: 0;
      width: size.$base-n-half;
      height: size.$base-n-half;
      padding: size.$quarter;
    }

    input {
      height: size.$double;
    }
  }

  .warning {
    @extend .row;
    @include mixins.center('horizontal');
  }

  @include mixins.warning;
}