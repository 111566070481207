@use '~/styles/size';
@use '~/styles/color';

.container {
  overflow: hidden;
  height: 100%;

  &[data-direction='horizontal'] {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .content {
    &[data-direction='horizontal'] {
      overflow-x: auto;
      overflow-y: hidden;
    }

    &[data-direction='vertical'] {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &[data-scrollbar='false'] {
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .arrows {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid #979797;
    background-color: rgba($color: color.$white, $alpha: 0.8);

    img {
      width: 16px;
      height: 16px;
    }
  }
}
