@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

.tabs {
  padding-top: size.$quarter;
  display: flex;
  position: relative;

  .wrapper {
    display: flex;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &::after {
    @include mixins.stick($top: 100%, $left: size.$n-base, $right: size.$n-base);
    display: block;
    content: '';
    position: absolute;
    background-color: color.$secondary;
    height: 1px;
  }
}
