@import '~/components/styles.scss';

.itemsList {
  @include widget-scrollable;
  padding-right: $half-unit;
  margin: 0;
  padding-bottom: $base-unit;
}

.group {
  padding: $quarter-unit 0;

  .title {
    @include subtitle;
  }
}
