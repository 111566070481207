@use '~/styles/color';
@use '~/styles/size';

.popupEditable {
  color: color.$text;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: size.$quarter;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow: hidden;

  input {
    background: transparent;
    border: none;
    min-width: 3ch;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  span {
    &.isImperial {
      &::after {
        content: '"';
        margin-left: 0px;
      }
    }

    &.isMetric {
      &::after {
        content: 'mm';
        margin-left: 0px;
      }
    }
  }
}
