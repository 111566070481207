@use 'color';
@use 'font';
@use 'size';
@use 'utils';

@mixin h-full {
  height: 100%;
}

@mixin w-full {
  width: 100%;
}

@mixin fill-full {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

@mixin stick($top: 0, $right: 0, $bottom: 0, $left: 0) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin center($axis: 'both') {
  display: flex;
  @if $axis == 'horizontal' {
    justify-content: center;
  }
  @if $axis == 'vertical' {
    align-items: center;
  }
  @if $axis == 'both' {
    align-items: center;
    justify-content: center;
  }
}

@mixin border($size: 1px, $color: color.$secondary) {
  border: $size solid $color;
}

@mixin border-bottom($size: 1px, $color: color.$secondary) {
  border-bottom: $size solid $color;
}

@mixin border-radius($type: 'default') {
  @if $type == 'small' {
    border-radius: utils.rem(2px);
  } @else if $type == 'default' {
    border-radius: utils.rem(4px);
  } @else if $type == 'same' {
    border-radius: inherit;
  }
}

@mixin subtitle {
  font-size: font.size('small');
  font-weight: bold;
  color: color.$secondary-dimmed;
}

@mixin filter($color: 'accent') {
  @if $color == 'accent' {
    filter: invert(46%) sepia(69%) saturate(2044%) hue-rotate(339deg) brightness(92%) contrast(90%);
  } @else if $color == 'secondary' {
    filter: brightness(0)
      saturate(100%)
      invert(67%)
      sepia(8%)
      saturate(335%)
      hue-rotate(123deg)
      brightness(92%)
      contrast(92%);
  } @else if $color == 'disabled' {
    filter: brightness(0)
      saturate(100%)
      invert(83%)
      sepia(3%)
      saturate(317%)
      hue-rotate(185deg)
      brightness(93%)
      contrast(85%);
  } @else if $color == 'white' {
    filter: brightness(0) invert(1);
  }
}

@mixin phoneField($size) {
  .number {
    border: none;
    padding: 0;
    cursor: inherit;
    color: inherit;
    width: inherit;
    height: inherit;
    @include font.default;
    font-size: $size;
  }

  .numberDropdown {
    display: none;
  }
}


@mixin warning {
  .warning {
    @include border-radius;
    color: color.$white;
    box-shadow: utils.shadow();
    background-color: color.$warning;
    padding: size.$half;
    margin-bottom: size.$half;
    align-items: center;

    text {
      @include subtitle;
    }

    .img {
      height: 100%;
      max-height: size.$base;
      margin: size.$half;
      transform: translateX(utils.n(utils.rem(4px)));
    }
  }
}
