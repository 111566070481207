@import '~/components/styles.scss';

.widget {
  display: flex;
  flex-direction: column;
  padding: $base-unit;
  height: 100%;

  header > .actions,
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  header {
    @include font-small;
    padding-bottom: $half-unit;

    .title {
      @include subtitle;
    }

    & > .actions {
      margin-bottom: $half-unit;

      .edit {
        display: flex;
        align-items: center;

        & > .title {
          color: $text-grey;
        }

        & > .image {
          width: $base-unit + $half-unit;
          height: $base-unit + $half-unit;
          cursor: pointer;
        }
      }

      .delete {
        &.title {
          color: $color-error;
          cursor: pointer;
        }

        &.disabled {
          color: $color-disabled;
          cursor: default;
        }

        outline: none;
        border: none;
      }
    }
  }

  main {
    @include widget-scrollable;
    padding-right: $half-unit;
    flex: 1;
  }

  footer {
    gap: $half-unit;
    padding-top: $half-unit;
  }
}
