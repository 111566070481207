@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: size.$base 0 size.$base size.$base;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: font.size('big');

  .controls {
    display: flex;
    align-items: center;

    .addNew {
      display: flex;
      align-items: top;
      font-size: font.size('big');
      color: color.$secondary;
      padding: size.$quarter size.$double 0 0;
      font-weight: normal;

      img {
        padding: 0 size.$quarter size.$quarter 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    img {
      &[data-active='true'] {
        @include mixins.filter('accent');
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.userList {
  min-height: utils.rem(100px);
  max-height: utils.rem(610px);
  overflow: auto;
}
