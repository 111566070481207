@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/color';

.container {
  @include mixins.center('vertical');
  @include mixins.border-radius('small');
  justify-content: space-between;
  background-color: color.$secondary;
  color: color.$white;
  margin: 0 size.$n-base;
  padding: size.$half size.$base;
  font-weight: bold;

  .back-button {
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    cursor: pointer;

    img {
      margin-right: size.$quarter;
      margin-top: utils.n(utils.rem(2px));
    }
  }
}
