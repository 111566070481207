@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.overlay {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;
  background-color: color.$shadow;
  pointer-events: all;

  .window {
    @include mixins.border-radius;
    padding: size.$base;
    background-color: color.$white;
    box-shadow: utils.shadow();

    .widget {
      margin: size.$n-base;
      padding: 0;
      width: utils.rem(670px);
      height: utils.rem(220px);
      display: flex;
      margin-top: size.$n-base;
      font-family: Arial, Helvetica, sans-serif;

      .backgroundImage {
        position: relative;
        width: 50%;
        background-size: cover;
        background-image: linear-gradient(
            180deg,
            #0d0f11 0%,
            rgba(14, 16, 18, 0.95) 11.35%,
            rgba(21, 26, 30, 0.71) 100%
          ),
          url('../../assets/icons/tp.png');

        img {
          position: absolute;
          z-index: 2;

          &.not_found {
            width: utils.rem(120px);
            height: utils.rem(120px);
            left: 50%;
            top: 50%;
            margin-left: utils.rem(-60px);
            margin-top: utils.rem(-60px);
          }

          &.forbidden {
            width: utils.rem(260px);
            height: utils.rem(160px);
            left: 20%;
            top: 40%;
            margin-left: utils.rem(-30px);
            margin-top: utils.rem(-45px);
          }

          &.custom {
            width: utils.rem(160px);
            height: utils.rem(160px);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }

      .actionContainer {
        margin: utils.rem(27px) 1rem 1rem 1rem;
        width: utils.rem(303px);

        .descriptionContainer {
          margin: 0 8px 0 0;

          h1 {
            margin: 0;
            font-size: 1rem;
            line-height: utils.rem(19px);
          }

          .description {
            margin-top: utils.rem(6px);
          }

          .description:first-child {
            font-family: Arial, sans-serif;
            line-height: utils.rem(17px);
          }
        }

        .buttonContainer {
          margin-top: utils.rem(85px);
        }
      }
    }
  }
}
