@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.container {
  @include mixins.border-radius;
  margin-bottom: size.$half;
  width: utils.rem(215px);
  box-shadow: utils.shadow();
  background-color: color.$white;
  z-index: 10;

  .spacer {
    height: size.$half;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: size.$base + size.$base;

    .button {
      display: flex;
      align-items: center;
      color: color.$text;
      padding: size.$quarter 0 size.$quarter size.$quarter;
      width: 100%;

      &[data-active='true'] {
        color: color.$accent;
    
        img {
          @include mixins.filter(color.$accent);
        }
      }

      img {
        padding-right: size.$quarter;
      }

      &:hover {
        cursor: pointer;
        background-color: color.$background-active;
      }
    }
  }
}
