@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.icon {
  height: size.$half + size.$quarter;

  &[data-active='true'] {
    @include mixins.filter;
  }
}

.dropdown {
  @include mixins.border-radius;
  min-width: utils.rem(131px);
  box-shadow: utils.shadow($blur: utils.rem(10px));
  margin-right: size.$half;
  background: color.$white;
  display: flex;
  flex-direction: column;

  .option {
    padding: size.$half;
    color: color.$text;

    &:first-child {
      border-top-left-radius: size.$quarter;
      border-top-right-radius: size.$quarter;
    }

    &:last-child {
      border-bottom-left-radius: size.$quarter;
      border-bottom-right-radius: size.$quarter;
    }

    &:hover {
      cursor: pointer;
      background-color: color.$background-active;
    }
  }
}
