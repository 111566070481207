$white: #ffffff;
$disabled: #b9bbc0;
$text: #151a1e;
$success: #37c18e;
$warning: #e79819;
$error: #f44336;
$background: #ffffffcc;
$background-active: #edf0f6;
$shadow: #00000026;
$light-shadow: #0000001e;
$accent: #e15e37;
$accent-dimmed: #c2512f;
$accent-dark: #7a2b14;
$secondary: #91a19f;
$secondary-dimmed: #5f6b6a;
$secondary-transparent: #91a19f0d;
$label-background: #ffffff80;
$label-color: #404040;
