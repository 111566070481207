@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/color';

.field {
  grid-column: span 1;
  padding-bottom: 10px;
}

.label {
  color: #5f6b6a;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  text-align: left;
  padding-bottom: 6px;
  padding-left: 1px;
  margin: auto;
}
