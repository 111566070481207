@import '~/components/styles.scss';

.header {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $text-grey;

  &:hover,
  &[data-open='true'] {
    color: $color-terracotta;
  }

  .image {
    width: $base-unit + $half-unit;
    height: $base-unit + $half-unit;
  }
}

.body {
  position: absolute;
  background-color: $color-white;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: $quarter-unit;
  padding: $quarter-unit $half-unit;
  display: none;

  &[data-open='true'] {
    display: block;
  }
}
