@use '~/styles/utils';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';

.shortcut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: utils.rem(300px);
  padding-bottom: utils.rem(18px);

  .name {
    padding-top: size.$quarter;
  }
  
  .value {
    @include mixins.border;
    @include mixins.border-radius(small);
    padding-top: size.$quarter;
    width: utils.rem(100px);
    background-color: color.$white;
    text-align: center;
    height: utils.rem(25px);
  }
}

.shortcut:last-of-type {
  padding-bottom: 0;
}

