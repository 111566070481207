@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.content {
  position: relative;

  .title {
    padding: size.$half size.$base;
    font-weight: bold;
    display: flex;
    width: fit-content;

    &:hover {
      cursor: pointer;
      color: color.$secondary-dimmed;

      img {
        filter: opacity(1);
      }
    }

    img {
      padding-left: size.$quarter;
      filter: opacity(0.7);
    }
  }

  .block {
    min-height: size.$double;
    max-height: 300px; 
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    display: flex;
    flex-wrap: wrap;

    .address {
      flex: 0 1 calc(50% - 1rem);
      min-width: 0;
      color: color.$text;
      margin: size.$half;
      @include mixins.border;
      @include mixins.border-radius;
      display: flex;
      justify-content: space-between;
    }
  }
}