@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';
@use '~/styles/font';

.button {
  @include mixins.border;
  @include mixins.border-radius;
  @include mixins.center;
  background-color: color.$white;
  cursor: pointer;
  height: size.$double;
  padding: 0 calc(calc(calc(size.$double + size.$quarter) / 2));

  .icon {
    margin-right: size.$quarter;
    width: utils.rem(24px);
    height: utils.rem(24px);
  }

  .icon + .content {
    @include mixins.h-full;
    @include mixins.center;
    padding-top: utils.rem(2px);
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &[data-size='big'] {
    font-size: font.size('big');
    height: utils.rem(38px);
    padding: 0 size.$base;
  }

  &[data-size='small'] {
    font-size: font.size('small');
    height: utils.rem(24px);
    padding: 0 size.$quarter;
  }

  &[data-variant='primary'] {
    border-color: color.$accent;
    background-color: color.$accent;
    color: color.$white;
  }

  &[data-variant='secondary'] {
    background-color: color.$secondary;
    color: color.$white;
  }

  &[data-variant='primary'],
  &[data-variant='secondary'] {
    .icon {
      filter: invert(100%) sepia(52%) saturate(2%) hue-rotate(212deg) brightness(108%) contrast(101%);
    }
  }

  &[data-active='true'] {
    color: color.$accent;

    .icon {
      filter: invert(46%) sepia(69%) saturate(2044%) hue-rotate(339deg) brightness(92%) contrast(90%);
    }
  }

  &[data-wide='true'] {
    width: 100%;
  }

  &:not([disabled]) {
    &[data-size='default']:hover {
      padding: 0 calc(calc(calc(calc(size.$double + size.$quarter) / 2)) - 1px);
    }

    &[data-size='big']:hover {
      padding: 0 calc(size.$base - 1px);
    }

    &[data-size='default'],
    &[data-size='big'] {
      &:hover {
        border-width: 2px;
      }
    }

    &[data-variant='default']:active {
      background-color: rgba(color.$white, 0.05);
    }

    &[data-variant='primary'] {
      &:active,
      &:hover {
        border-color: color.$accent-dimmed;
        background-color: color.$accent-dimmed;
      }

      &:active {
        border-color: color.$accent-dark;
        background-color: color.$accent-dimmed;
      }
    }

    &[data-variant='secondary'] {
      &:hover,
      &:active {
        border-color: color.$white;
      }

      &:active {
        background-color: color.$secondary-transparent;
      }
    }
  }
}
