@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';
@use '~/components/styles';

.content {
  .row {
    display: flex;
    font-family: Arial, sans-serif;

    .element {
      padding: size.$quarter;
    }

    .element_growing {
      flex: 100%;
      min-width: 0;
      @extend .element;
    }

    .element_centered-content {
      @extend .element;
      display: flex;
      align-items: center;
    }

    .image {
      cursor: pointer;
      width: size.$base-n-half;
      height: size.$base-n-half;
      padding: size.$quarter;
    }
  }
}
