@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.overlay {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;
  background-color: color.$shadow;
  pointer-events: all;

  .window {
    @include mixins.border-radius;
    padding: size.$base;
    background-color: color.$white;
    box-shadow: utils.shadow();

    .modal {
      margin: size.$n-base;
      @include mixins.border-radius;
      position: relative;
      min-width: size.$modal-min-width;
    }
    .content {
      &[data-column='false'] {
        display: flex;
        flex-direction: row;
      }

      .mainContent {
        min-width: size.$modal-min-content-width;

        &[data-variant='secondary'] {
          border-right: solid 1px color.$secondary;
        }
      }

      .sidebar {
        min-width: size.$modal-min-sidebar-width;
      }
    }
  }
}
