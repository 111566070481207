@use '~/styles/size';
@use '~/styles/mixins';

.container,
.content,
.group {
  @include mixins.fill-full;
}

.container {
  overflow-y: clip;

  .content {
    margin: 0 size.$n-base 0 size.$n-base;

    .group,
    .not-found {
      padding: size.$base;
    }

    .checkboxContainer {
      margin-bottom: size.$base;
    }
  }
}
