@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.content,
.container {
  @include mixins.fill-full;
}

.content {
  padding: size.$half;
  padding-bottom: size.$base;

  .container {
    overflow-y: clip;
  }

  header,
  footer,
  .warning {
    @include mixins.center('horizontal');
  }

  header {
    justify-content: start;
    padding-bottom: size.$half;

    .title {
      @include mixins.subtitle;
      margin-right: size.$quarter;
    }
  }

  @include mixins.warning;

  main {
    @include mixins.fill-full;
    overflow-y: hidden;
    margin-right: size.$n-quarter;

    .choice-no,
    .hint {
      padding-top: size.$half;
    }

    .hint {
      @include mixins.subtitle;
    }
  }

  footer {
    justify-content: end;
    padding-top: size.$half;
  }
}
