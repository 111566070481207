@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';
@use '~/styles/utils';

.modal {
  width: utils.rem(396px);
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;

  .title {
    text-align: left;
    font-weight: bold;
    text-transform: none;
    font-size: font.size('big');
    margin-bottom: size.$quarter;
  }

  .subtitle {
    font-weight: normal;
    font-size: font.size('normal');
    padding-bottom: size.$base;
  }

  .nameInput {
    font-weight: bold;
    font-size: font.size('small');
    color: color.$text;
    padding-bottom: size.$base-n-half;
  }

  .inputTitle {
    font-weight: bold;
    font-size: font.size('small');
    color: color.$secondary-dimmed;
    padding: size.$half 0 size.$quarter;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    button:first-of-type {
      margin-right: size.$half;
    }
  }
}
