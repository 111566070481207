@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';
@use '~/styles/font';

.tab {
  font-size: font.size('big');
  font-weight: bold;
  color: color.$secondary;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: inherit;
  min-width: utils.rem(90px);
  min-height: size.$double;
  padding: 0;
  position: relative;

  &[data-active='true'],
  &[aria-disabled='false']:hover {
    color: color.$text;
  }

  .content {
    padding: size.$quarter size.$half size.$quarter size.$half;
    word-wrap: none;
    text-align: center;
  }

  &[data-active='true'] .highlight {
    position: absolute;
    @include mixins.stick($top: calc(100% - size.$quarter));
    @include mixins.w-full;
    height: size.$quarter;
    background-color: color.$accent;
    border-top-left-radius: size.$quarter;
    border-top-right-radius: size.$quarter;
  }
}
