@use '~/styles/size';
@use '~/styles/color';
@use '~/styles/mixins';
@use '~/styles/utils';

.container {
  top: 0;
  left: 0;
  transform: translate(min(var(--x), calc(100vw - 100%)), min(var(--y), calc(100vh - 100%)));
  position: absolute;
  padding: size.$quarter size.$half;
  @include mixins.border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: utils.shadow($blur: utils.rem(5px));
  background-color: color.$background;
  z-index: 1;
}
