@use '~/styles/color';
@use '~/styles/utils';

.headline {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px color.$secondary;
  min-height: utils.rem(50px);

  .content {
    min-width: 95%;
  }
}
