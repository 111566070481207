@use '~/styles/color';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.tooltip {
  padding: size.$half;
  background-color: color.$white;
  max-width: utils.rem(168px);
  box-shadow: utils.shadow();

  &-image {
    width: utils.rem(152px);
    height: utils.rem(152px);
  }

  &,
  &-image {
    @include mixins.border-radius('small');
  }
}
