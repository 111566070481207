@use 'sass:math';
@use '~/styles/mixins';
@use '~/styles/font';
@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/color';

.item {
  display: flex;
  align-items: center;
  padding: math.div(size.$half, 2) 0;
  padding-right: size.$half;
  margin-right: size.$quarter;

  &.selectable:hover:not(.selected) {
    background-color: rgba(color.$secondary, 0.15);
    .itemImg > div {
      visibility: visible;
    }
  }

  &.selected {
    background-color: rgba(color.$accent, 0.15);
    &:hover .itemImg > div {
      visibility: visible;
    }
  }

  .arrow {
    min-width: size.$half + size.$base;
    padding: size.$half;
    padding-left: 0;

    img {
      cursor: pointer;
      width: size.$base;
      object-fit: none;
    }

    &.expanded {
      > img {
        transform: rotate(90deg);
      }
    }
  }

  &Img {
    width: size.$base * 2;
    margin-right: size.$half;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      position: relative;
      right: size.$base * 2;
      visibility: hidden;
      background-color: rgba(color.$background, 0.5);
    }

    img {
      width: size.$base * 2;
      height: size.$base * 2;
    }
  }

  &Number {
    margin-right: size.$base;
  }

  &Description {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
  }

  &TextWithEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Quantity {
    min-width: 35px;
    margin-left: size.$half + size.$quarter;
    margin-right: size.$quarter;
    text-align: right;
    display: inline-block;
    justify-content: space-between;
    vertical-align: text-top;

    img {
      width: size.$base;
      margin-left: size.$quarter;
      @include mixins.filter('secondary');
    }
  }
}

.tooltip {
  @include mixins.border-radius('small');
  min-width: utils.rem(40px);
  padding: size.$half;
  background-color: color.$white;
  margin: size.$quarter;
  box-shadow: utils.shadow($blur: size.$quarter);
}
