@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.row {
  cursor: pointer;

  &:hover {
    background-color: fade-out(color.$secondary, 0.85);
  }

  .row-item {
    padding: utils.rem(10px) 0 utils.rem(10px) utils.rem(40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc((size.$modal-min-content-width - utils.rem(40px))/3);
    max-width: calc((size.$modal-min-content-width - utils.rem(40px))/3);
    position: relative;

    .icon {
      position: absolute;
      width: size.$base;
      height: size.$base;
      left: utils.rem(10px);
      bottom: utils.rem(9px);
      @include mixins.filter('secondary');
    }
  }

  &[data-selected='true'] {
    background-color: color.$background-active;
    font-weight: bold;

    .row-item:first-child {
      color: color.$accent;
    }
  }
  .row-item:last-child {
    padding: utils.rem(10px) size.$base;
    width: utils.rem(40px);
  }
}
