@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

.checkbox {
  @include mixins.center('vertical');
  cursor: pointer;

  img {
    margin-right: size.$half;
  }

  .disabled {
    color: color.$disabled;
  }
}
