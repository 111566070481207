@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';

.userData {
  display: flex;
  flex-direction: row;
  padding: size.$base, size.$base, size.$base * 1.5;

  .data {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: size.$base;
    min-width: 200px;
    flex: 1;

    .info {
      font-size: font.size('small');
      color: color.$secondary;
      padding: size.$half size.$base * 4 0 size.$quarter;
      text-align: left;

      .title {
        display: block;
        padding-bottom: size.$half;
        font-weight: bold;
      }

      &[data-wide='true'] {
        width: 86%;
      }

      .fieldValue {
        font-family: Arial, Helvetica, sans-serif;
        font-size: font.size('big');
        color: color.$text;
        text-align: left;
      }
    }
  }
}
