@import '../styles.scss';

.measurementUnit {
  cursor: pointer;
}

.toolboxPanel {
  border-radius: $default-border-radius;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
}

.logo {
  height: 40px;
  width: 170px;
  padding: $half-unit 0 0 $quarter-unit;

  img {
    height: 27px;
  }
}

.toolboxPaneltoolboxPanel {
  margin: 0;
  padding: 0;
}

.mainPanel {
  position: absolute;
  height: 40px;
  left: $base-unit;
  top: $base-unit;
  display: flex;
  justify-content: left;
  gap: $base-unit;
}

.userControls {
  position: absolute;
  height: 100%;
  width: 40px;
  right: $base-unit;
  top: $base-unit;
  display: flex;
  justify-content: flex-end;

  &[data-active='true'] {
    z-index: 10;

    .toolboxPanel {
      img {
        filter: invert(46%) sepia(69%) saturate(2044%) hue-rotate(339deg) brightness(92%) contrast(90%);
      }
    }
  }

  .account {
    margin-top: 45px;
  }
}

.user {
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 24px;
    width: 24px;
    padding: unset;
  }
}

.projectInfo {
  position: absolute;
  margin-top: 4px;
  z-index: 1;
}

.quote {
  position: absolute;
  height: 40px;
  width: auto;
  min-width: 118px;
  right: 72px;
  top: $base-unit;
  display: block;
  font-weight: bold;
  padding-right: 10px;

  img {
    height: 24px;
    width: 24px;
    padding: unset;
  }
}

.fps {
  position: absolute;
  height: 40px;
  width: 70px;
  right: 310px;
  top: $base-unit;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }
}

.rightPanel {
  position: absolute;
  width: 40px;
  right: $base-unit;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: $base-unit;

  .singleButtonPanel {
    height: 40px;
  }
}

.horizontalPanel {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;

  .hidden {
    display: none;
  }
}

.verticalPanel {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
}
