@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/font';

.container {
  background-color: color.$white;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  border-radius: 0.25rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 3;

  max-width: 500px;

  .bannerImage {
    border-radius: 0.25rem;

    max-width: 500px;
  }

  .closeButton {
    position: absolute;
    right: 6px;
    z-index: 4;

    &:hover {
      img {
        filter: invert(46%) sepia(69%) saturate(2044%) hue-rotate(339deg) brightness(92%) contrast(90%);
      }
    }
  }
}
