@use '~/styles/mixins';
@use '~/styles/size';

.container {
  @include mixins.center('vertical');
  padding-top: size.$half;
  justify-content: space-between;
  z-index: 1;

  .category-image {
    width: size.$double;
    height: size.$double;
  }

  .checkbox {
    padding-left: size.$half;
  }
}
