@use '~/styles/size';
@use '~/styles/color';


@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spinner{
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px color.$secondary;
  border-bottom-color: transparent;
  border-radius: 50%;
  content: "";
  height: size.$base + size.$base;
  width: size.$base + size.$base;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}