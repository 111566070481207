@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.controls {
  padding-top: size.$half;

  &:hover {
    cursor: pointer;
  }
  &[data-active='true'] {
    @include mixins.filter('accent');
  }
}

.dropdown {
  min-width: utils.rem(131px);
  box-shadow: utils.shadow(
    $blur: (
      utils.rem(10px),
    )
  );

  @include mixins.border-radius;
  margin-right: size.$half;
  background: color.$white;
  display: flex;
  flex-direction: column;

  a {
    @include mixins.border-radius;
    padding: size.$half;
    font-family: Arial, Helvetica, sans-serif;
    font-size: font.size('normal');
    text-decoration: none;
    color: color.$text;

    &:hover {
      cursor: pointer;
      background-color: color.$background-active;
    }
  }
}

.user {
  td {
    max-width: utils.rem(120px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &[data-inactive='false'] {
    &:hover {
      background-color: color.$background-active;
    }
  }
}

.loader {
  border: utils.rem(2px) solid color.$disabled;
  border-top: utils.rem(2px) solid color.$text;
  border-radius: 50%;
  width: size.$base;
  height: size.$base;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
