@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.overlay {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;
  background-color: color.$shadow;
  pointer-events: all;

  .window {
    @include mixins.border-radius;
    padding: size.$base;
    background-color: color.$white;
    box-shadow: utils.shadow();

    .modal {
      margin: size.$n-base;
      min-width: size.$modal-min-width;

      .buttons {
        margin: size.$base;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          padding: size.$quarter;
        }
      }

      .credentials {
        padding: size.$base;

        .inputTitle {
          font-weight: bold;
          font-size: font.size('small');
          color: color.$secondary;
          padding: size.$half 0 size.$quarter;
        }
      }
    }
  }
}

.buttons {
  margin: size.$base;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    padding: size.$quarter;
  }
}
