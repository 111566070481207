@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.addresses {
  font-family: Arial, Helvetica, sans-serif;
  font-size: font.size('normal');
  color: color.$secondary;
  @include mixins.border;
  border-radius: 0 0 4px 4px;
  padding: 0 size.$half;
  text-align: left;

  .title {
    padding: size.$half;
    font-weight: bold;
    display: flex;
    width: fit-content;

    &:hover {
      cursor: pointer;
      color: color.$secondary-dimmed;

      img {
        filter: opacity(1);
      }
    }

    img {
      padding-left: size.$quarter;
      filter: opacity(0.7);
    }
  }

  .block {
    min-height: size.$base * 2;
    max-height: 300px;
    overflow: auto;
    text-align: justify;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .address {
      color: color.$text;
      margin: size.$half;
      @include mixins.border;
      @include mixins.border-radius;
      display: flex;
      justify-content: space-between;

      .dots {
        padding: size.$quarter;

        img {
          cursor: pointer;
        }
      }

      div {
        padding: size.$quarter;
      }
    }
  }
}

.dropdown {
  min-width: utils.rem(131px);
  box-shadow: utils.shadow(
    $blur: (
      utils.rem(10px),
    )
  );

  @include mixins.border-radius;
  margin-right: size.$half;
  background: color.$white;
  display: flex;
  flex-direction: column;

  a {
    @include mixins.border-radius;
    padding: size.$half;
    font-family: Arial, Helvetica, sans-serif;
    font-size: font.size('normal');
    text-decoration: none;
    color: color.$text;

    &:hover {
      cursor: pointer;
      background-color: color.$background-active;
    }
  }
}
