@use 'sass:math';
@use '~/styles/mixins';
@use '~/styles/font';
@use '~/styles/utils';

.item {
  &PriceContainer {
    // flex: 1000;
    flex: 90;
    min-width: utils.rem(75px);
    max-width: utils.rem(130px);
    text-align: right;
    display: inline;
  }

  &PriceWithDiscount {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    $breakpoint: utils.rem(125px);
    --multiplier: calc(#{$breakpoint} - 100%);

    .itemCrossedOutPrice {
      font-size: font.size(smaller);
      text-decoration: line-through;
      flex-basis: calc(var(--multiplier) * 999);
    }
    .itemPrice {
      flex-basis: calc(var(--multiplier) * 999);
    }
  }
}
