@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

.chip {
  @include mixins.border-radius('small');
  background-color: color.$white;
  font-weight: bold;
  padding: calc(size.$quarter + 1px) calc(size.$half + 1px);
  cursor: pointer;
  white-space: nowrap;
  border: 2px solid transparent;
  outline: 1px solid color.$secondary;

  &:hover {
    outline: 2px solid color.$secondary;
  }

  &[data-active='true'] {
    background-color: color.$secondary;
    color: color.$white;
  }

  &:not(:first-child) {
    margin-left: size.$half;
  }
}
