@use 'utils';
@use 'size';

@function size($size: 'normal') {
  @if $size == smaller {
    @return utils.rem(11px);
  } @else if $size == 'small' {
    @return utils.rem(12px);
  } @else if $size == 'normal' {
    @return utils.rem(14px);
  } @else if $size == 'big' {
    @return size.$base;
  } @else {
    @error 'Unknown value #{$size}';
  }
}

@mixin default {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: size('normal');
  line-height: normal;
  letter-spacing: normal;
}
