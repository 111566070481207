@use '~/styles/mixins';
@use '~/styles/font';
@use '~/styles/color';
@import '~/components/styles.scss';

.widget {
  @include font-default;
  @include mixins.fill-full;
  color: $text-grey;
  padding: $base-unit;
  overflow-y: auto;

  header,
  footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: -$half-unit;
  }

  header {
    position: relative;
    padding-bottom: $base-unit;
    margin-bottom: $base-unit;

    &::after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: $n-base-unit;
      width: calc(100% + $base-unit * 2);
      height: 1px;
      background-color: $cool-grey;
    }

    .title,
    .subtitle,
    .name {
      @include font-bold;
    }

    .title,
    .name {
      @include font-big;
    }

    .subtitle {
      color: $color-gunmetal;
      margin-top: $quarter-unit;
    }
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;

    .section {
      margin-bottom: $half-unit;
    }

    .inputName {
      @include subtitle;
      margin-bottom: $quarter-unit * 1.5;
    }
  }

  footer {
    padding-top: $base-unit;
    margin-top: $base-unit;

    .send-to {
      font-size: font.size('big');
      color: color.$text;
      margin-bottom: $half-unit;
    }

    .buttons {
      display: flex;
      margin-top: $half-unit;
      gap: $base-unit;
    }
  }
}
