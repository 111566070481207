@use '~/styles/color';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.overlay {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;
  background-color: color.$shadow;
  pointer-events: all;
}

.window {
  @include mixins.border-radius;
  padding: size.$base;
  background-color: color.$white;
  box-shadow: utils.shadow();
}
