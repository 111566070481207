@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';

.section {
  display: grid;
  padding: size.$base;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;

  &[data-border='true'] {
    border-bottom: solid 1px color.$secondary;
  }

  .label {
    font-weight: bold;
    padding-bottom: size.$half;
    color: color.$secondary-dimmed;
    font-size: font.size('small');
  }
}
