@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/mixins';

.content {
  @include mixins.fill-full;
  margin-right: utils.n(utils.rem(12px));
  padding: size.$base 0;
  overflow-y: hidden;

  @-moz-document url-prefix() {
    padding-bottom: size.$double;
  }

  .group {
    @include mixins.w-full;
    display: grid;
    grid-template-columns: repeat(auto-fill, utils.rem(144px));
    gap: size.$catalog-widget-gap;
    justify-content: space-between;
    padding-right: utils.rem(6px);
  }
}
