@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.header {
  display: flex;

  .title {
    @include mixins.w-full;
    padding: size.$base;
    font-weight: bold;
    font-size: font.size('big');
  }

  .controls {
    display: flex;

    .create-button {
      @include mixins.center('vertical');
      text-decoration: none;
      color: color.$secondary-dimmed;

      img {
        height: size.$base;
        margin-right: size.$half;
      }
    }

    .sorting-container {
      &[data-disabled='true'] {
        pointer-events: none;
      }

      .sorting-button {
        margin: utils.rem(13px) size.$half utils.rem(13px) size.$double;
        cursor: pointer;

        &[data-active='true'] {
          @include mixins.filter;
        }

        &[data-disabled='true'] {
          @include mixins.filter('disabled');
        }
      }
    }

    .search {
      @include mixins.center('vertical');
      width: utils.rem(168px);

      & > div:focus-within {
        outline: 1px solid color.$secondary;
      }

      &[data-empty='false'] img:last-child {
        display: none;
      }

      &[data-disabled='true'] > div {
        @include mixins.border($color: color.$disabled);
        pointer-events: none;

        img {
          @include mixins.filter('disabled');
        }
      }
    }
  }
}

.main {
  width: size.$modal-min-content-width;
  height: size.$modal-standard-content-height;
  display: flex;

  &[data-scrollbar='true'] {
    padding-right: size.$quarter;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-collapse: collapse;

    tbody {
      overflow: auto;
    }

    .header-item {
      padding: size.$base 0 size.$half utils.rem(40px);
      color: color.$secondary-dimmed;
      font-size: utils.rem(12px);
      font-weight: bold;
      line-height: utils.rem(14px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc((size.$modal-min-content-width - utils.rem(40px))/3);
      max-width: calc((size.$modal-min-content-width - utils.rem(40px))/3);
      text-align: left;
    }
  }
}

.no-customers-container {
  width: size.$modal-min-content-width;
  height: size.$modal-standard-content-height;

  .not-found {
    padding: size.$base;
  }

  .no-customers {
    @include mixins.center;
    width: size.$modal-min-content-width;
    height: size.$modal-standard-content-height;
    flex-direction: column;
    color: color.$secondary-dimmed;
    font-weight: bold;

    img {
      @include mixins.filter('secondary');
      height: 40px;
    }
  }
}

.no-selected {
  padding: size.$base;
  font-weight: bold;
  font-size: font.size('big');
}
