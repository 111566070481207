@import '~/components/styles.scss';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  .radio {
    margin-right: $half-unit;
    width: $base-unit + $half-unit;
    height: $base-unit + $half-unit;
  }

  .children {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}
