@use '~/styles/color';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &[data-disabled='false'] {
    &:hover {
      color: color.$secondary;
      cursor: pointer;

      .buttonContainer {
        @include mixins.border-radius('default');
        background-color: rgb(145, 161, 159, 0.5);
      }
    }

    &[data-active='true'] {
      color: color.$accent;

      .buttonContainer {
        @include mixins.border-radius('default');
        background-color: color.$accent;
      }
    }
  }

  &[data-disabled='true'] {
    color: color.$disabled;
  }
}

.button {
  @include mixins.center('vertical');
  min-width: utils.rem(40px);
  min-height: utils.rem(40px);
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  font-weight: inherit;

  &[data-active='true'] {
    color: color.$accent;

    img {
      @include mixins.filter('white');
    }
  }

  &:disabled {
    cursor: default;
    color: color.$disabled;

    img {
      @include mixins.filter('disabled');
    }
  }

  .icon {
    width: utils.rem(24px);
    height: utils.rem(24px);
  }

  .dropdownArrowIcon {
    margin-bottom: size.$half;
  }

  .dropdownArrowWrapper {
    height: size.$half;
    display: flex;
    align-items: center;
  }

  .title {
    height: size.$base + size.$quarter;
  }

  .icon + .title + .dropdownArrowIcon {
    margin-left: size.$base;
  }
}

.tooltip {
  @include mixins.border-radius('default');
  background-color: color.$white;
  padding: size.$half;
  box-shadow: utils.shadow();

  &[data-placement='bottom-start'] {
    margin-top: size.$quarter;
    margin-left: size.$quarter;
  }

  &[data-placement='left-center'] {
    margin-right: utils.rem(2px);
  }
}

.buttonContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  padding-left: size.$half;
  flex: 1;
}

.loader {
  position: relative;
  padding-left: utils.rem(30px);
  flex: 1;
}
