@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.overlay {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;
  background-color: color.$shadow;
  pointer-events: all;

  .window {
    @include mixins.border-radius;
    padding: size.$base;
    background-color: color.$white;
    box-shadow: utils.shadow();

    .widget {
      margin: size.$n-base;
      padding: 0;
      width: 672px;
      height: 100%;
      display: flex;
      margin-top: size.$n-base;
      font-family: Arial, Helvetica, sans-serif;

      .imgBlock {
        position: relative;
        padding: 0;
        width: 50%;
        background-repeat: no-repeat, no-repeat;
        background-size: cover;
        background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0.216)),
          url('../../assets/icons/tp.png');

        img {
          position: absolute;
          z-index: 2;
          float: left;
          margin-left: 20%;
          margin-top: 10%;
        }
      }

      .header {
        vertical-align: text-top;
        position: absolute;
      }
    }
  }
}
