@use '~/styles/mixins';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/font';
@use '~/styles/utils';

.widget {
  @include font.default;
  @include mixins.fill-full;
  padding: size.$base;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: size.$base;
  margin-bottom: size.$half;
}

.title {
  font-size: font.size(big);
  font-weight: bold;
}

.default-main {
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding-right: size.$quarter;
  padding-top: 0;
}

.items-header {
  width: 100%;
}

.back-button {
  background-color: color.$secondary;
  color: color.$white;
  height: 32px;
  border: none;
  width: 70%;
  cursor: pointer;
  text-align: left;
  padding-left: 15px;
  letter-spacing: 0.9px;

  img {
    margin-right: size.$quarter;
    margin-top: utils.n(utils.rem(2px));
  }
}

.place-button {
  background-color: #e6a796;
  color: color.$white;
  width: 30%;
  height: 32px;
  border: none;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.form-main {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  padding: size.$base;
}
