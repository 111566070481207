@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.dropdown {
  min-width: utils.rem(127px);
  box-shadow: utils.shadow(
    $blur: (
      utils.rem(10px),
    )
  );

  @include mixins.border-radius;
  background: color.$white;
  margin-top: size.$quarter;
  display: flex;
  flex-direction: column;

  .option {
    @include mixins.border-radius;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: size.$quarter size.$half;
    font-family: Arial, Helvetica, sans-serif;
    font-size: font.size('normal');
    text-decoration: none;
    color: color.$text;

    &:hover {
      background-color: color.$background-active;
      cursor: pointer;
    }

    span {
      &[data-selected='true'] {
        color: color.$accent;
        font-weight: bold;
      }
    }

    img {
      &[data-selected='true'] {
        @include mixins.filter;
      }
    }
  }
}
