@use '~/styles/mixins';
@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/color';

.item {
  display: flex;
  align-items: center;
  margin: size.$half 0;
  padding-right: size.$half + size.$quarter;

  &:hover {
    .trashImg {
      visibility: visible;
    }
  }

  .arrow {
    min-width: size.$half + size.$base + size.$quarter;
    padding: size.$half;
    padding-left: 0;
    cursor: pointer;

    img {
      width: size.$base;
    }
  }

  .trashImg {
    opacity: 0.5;
    visibility: hidden;

    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
    }
  }

  &Img {
    min-width: size.$base * 2;
    height: size.$base * 2;
    margin-right: size.$half;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: size.$base * 2;
    }
  }

  &Number {
    margin-right: size.$base;
  }

  &Description {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: size.$base * 4;
    // max-width: 209px;
  }

  &Size {
    align-items: center;
    flex: 200;
    min-width: size.$base;
    max-width: size.$base * 2.5;
    white-space: nowrap;
    overflow: hidden;
  }

  &MachiningNotes {
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Quantity {
    min-width: 35px;
    margin-left: size.$half + size.$quarter;
    margin-right: size.$quarter;
    text-align: right;
  }

  &Price {
    min-width: 80px;
    text-align: right;
  }

  &TextWithEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &InputNumber {
    max-width: 80px;
    margin-left: size.$base;
  }
}

.tooltip {
  @include mixins.border-radius('small');
  min-width: utils.rem(40px);
  padding: size.$half;
  background-color: color.$white;
  margin: size.$quarter;
  box-shadow: utils.shadow($blur: size.$quarter);
}
