@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/font';

.container {
  @include mixins.center('horizontal');
  @include mixins.border;
  @include mixins.border-radius;
  background-color: color.$white;
  position: relative;
  height: size.$double;

  &[data-invalid='true'] {
    @include mixins.border($color: color.$error);
  }

  .input {
    @include mixins.border-radius;
    @include mixins.w-full;
    border: none;
    background-color: transparent;
    padding: size.$half;
    color: inherit;

    &::placeholder {
      color: color.$secondary;
    }

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .controls {
    @include mixins.h-full;
    @include mixins.center;
    padding-right: size.$quarter;

    img {
      cursor: pointer;
    }

    .search,
    .password {
      filter: invert(68%) sepia(8%) saturate(6%) hue-rotate(329deg) brightness(87%) contrast(88%);
    }

    .calendar,
    .search,
    .password {
      width: utils.rem(24px);
      height: utils.rem(24px);
    }

    .arrows {
      @include mixins.border-radius;
      display: flex;
      flex-direction: column;
      margin-right: size.$n-quarter;
      overflow: hidden;

      :active {
        background-color: color.$shadow;
      }

      img {
        width: size.$base;
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .error {
    position: absolute;
    top: size.$quarter;
    right: size.$quarter;
    cursor: pointer;

    &:hover {
      &+.error-message {
        visibility: visible;
      }
    }
  }

  .error_build-in {
    margin: size.$quarter;
    cursor: pointer;

    &:hover {
      &+.error-message {
        visibility: visible;
      }
    }
  }

  .error-message {
    @include font.default;
    box-shadow: utils.shadow();
    @include mixins.border-radius('small');
    position: absolute;
    background-color: color.$error;
    color: color.$white;
    top: calc(100% + size.$quarter);
    right: size.$quarter;
    padding: size.$half;
    z-index: 2;
    visibility: hidden;
  }

  ::-ms-reveal {
    display: none;
  }
}