@import '~/components/styles.scss';

.container {
  @include default-border-radius;
  position: relative;
  border: solid 1px $cool-grey;
  background-color: $color-white;

  .input {
    @include font-default;
    background-color: transparent;
    border: none;
    padding: $half-unit;
    width: 100%;
    height: $base-unit * 2;
    color: $text-grey;

    &::placeholder {
      @include font-default;
      color: $cool-grey;
    }
  }

  &.error {
    .input {
      width: calc(100% - $base-unit * 2);
    }

    .icon {
      position: absolute;
      top: $quarter-unit;
      right: $quarter-unit;
    }

    .error {
      @include default-shadow;
      @include font-default;
      border-radius: $half-radius;
      position: absolute;
      top: calc(100% + $quarter-unit);
      right: $quarter-unit;
      padding: $half-unit;
      background-color: $color-error;
      color: $color-white;
      z-index: 2;
    }
  }
}
