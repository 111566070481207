@import '~/components/styles.scss';

.widgetsBoundaries {
  width: calc(100vw - ($toolbox-height + $base-unit));
  height: calc(100vh - ($toolbox-height + $base-unit));
  position: fixed;
  top: $toolbox-height;
  left: $base-unit;
  pointer-events: none;
  // z-index: 1;
}
