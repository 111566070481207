@use 'sass:math';
@use '~/styles/mixins';
@use '~/styles/font';
@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/color';
// @import '~/components/styles.scss';

.item {
  display: flex;
  align-items: center;
  padding: math.div(size.$half, 2) 0;
  padding-right: size.$half;
  margin-right: size.$quarter;

  &.selectable:hover:not(.selected) {
    background-color: rgba(color.$secondary, 0.15);
    .itemImg > div {
      visibility: visible;
    }
  }

  &.selected {
    background-color: rgba(color.$accent, 0.15);
    &:hover .itemImg > div {
      visibility: visible;
    }
  }

  .arrow {
    min-width: size.$half + size.$base;
    padding: size.$half;
    padding-left: 0;

    img {
      cursor: pointer;
      width: size.$base + size.$quarter;
      object-fit: none;
    }

    &.expanded {
      > img {
        transform: rotate(90deg);
      }
    }
  }

  .trashImg {
    opacity: 0.5;

    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
    }
  }

  .notesImg {
    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
    }

    &[data-disabled='true'] {
      opacity: 0.5;
      pointer-events: none;
    }

    &.selected {
      > * {
        filter: invert(46%) sepia(69%) saturate(2044%) hue-rotate(339deg) brightness(92%) contrast(90%);
      }
    }

    &.add {
      > * {
        filter: invert(68%) sepia(8%) saturate(6%) hue-rotate(329deg) brightness(87%) contrast(88%);
      }
    }
  }

  .subtitle {
    font-size: font.size('small');
    margin: size.$base 0 size.$half size.$quarter;
  }

  .marginLeft {
    margin-left: size.$quarter;
  }

  &Img {
    width: size.$base * 2;
    margin-right: size.$half;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      position: relative;
      right: size.$base * 2;
      visibility: hidden;
      background-color: rgba(color.$background, 0.5);
    }

    img {
      width: size.$base * 2;
      height: size.$base * 2;
    }
  }

  &Number {
    margin-right: size.$base;
  }

  &Description {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
  }

  &TextWithEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Size {
    align-items: center;
    flex: 200;
    min-width: size.$base * 6 + size.$half;
    max-width: size.$base * 3;
    white-space: nowrap;
    overflow: hidden;
  }

  &InputNumber {
    max-width: 80px;
    margin-left: size.$base;
  }

  &Quantity {
    min-width: 35px;
    margin-left: size.$half + size.$quarter;
    margin-right: size.$quarter;
    text-align: right;
    display: inline-block;
    justify-content: space-between;
    vertical-align: text-top;

    img {
      width: size.$base;
      margin-left: size.$quarter;
      @include mixins.filter('secondary');
    }
  }

  &Price {
    min-width: utils.rem(100px);
    text-align: right;
  }

  &CrossedOutPrice {
    margin-right: size.$quarter;
    text-align: left;
    font-size: font.size('small');
    text-decoration: line-through;
  }

  &PriceWithDiscount {
    min-width: utils.rem(100px);
    text-align: right;
  }
}

.tooltip {
  @include mixins.border-radius('small');
  min-width: utils.rem(40px);
  padding: size.$half;
  background-color: color.$white;
  margin: size.$quarter;
  box-shadow: utils.shadow($blur: size.$quarter);
}
