@use '~/styles/size';

.container {
  padding-top: size.$half;

  .chips {
    display: flex;
    position: relative;
    overflow-y: auto;
    width: 100%;
    scrollbar-width: none;
    padding: 2px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}
