@use '~/styles/font';
@use '~/styles/utils';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';

.totalContainer {
  background-color: color.$success;
  color: color.$white;
  @include font.default;
  @include mixins.border-radius();
  margin-right: size.$half + size.$quarter;

  .totalWeight {
    display: flex;
    justify-content: space-between;
    padding: 0 size.$half;
    background-color: color.$secondary;
    line-height: utils.rem(27px);
    border-top-left-radius: utils.rem(4px);
    border-top-right-radius: utils.rem(4px);

    &EmptyContainer {
      min-width: utils.rem(148px);
    }

    .weightInfo {
      min-width: utils.rem(100px);

      .weightLine {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        min-width: utils.rem(140px);

        .weightTitle {
          text-align: right;
          min-width: utils.rem(81px);
          max-width: utils.rem(85px);
        }

        .weightValue {
          flex: 1;
          max-width: utils.rem(130px);
          min-width: utils.rem(75px);
          text-align: right;
        }
      }

      .emptyContainer {
        width: utils.rem(220px);
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-shrink: 1;
    align-items: center;
    padding: size.$half;

    &SeeDiscountDetails {
      min-width: utils.rem(148px);
      height: utils.rem(30px);

      .specialAgreement {
        font-size: font.size('small');
        color: color.$background;
        margin-top: utils.rem(5px);
      }

      .arrow {
        width: size.$half + size.$base;
        padding-left: 0;

        img {
          cursor: pointer;
          width: size.$base;
          object-fit: none;
          filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
        }

        &.expanded {
          > img {
            transform: rotate(90deg);
          }
        }
      }
    }

    .priceInfo {
      min-width: utils.rem(100px);
      margin-left: auto;

      .priceLine {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        min-width: utils.rem(140px);
        height: utils.rem(30px);
        margin-left: auto;
        margin-right: 0;

        .priceTitle {
          text-align: right;
          align-self: center;
          min-width: utils.rem(81px);
          flex: 10;
          max-width: utils.rem(85px);
        }

        .priceValue {
          align-self: center;
          flex: 1;
          max-width: utils.rem(130px);
          min-width: utils.rem(75px);
          text-align: right;

          .priceWithDiscount {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            margin-right: calc(size.$quarter / 2);

            .crossedOutPrice {
              font-size: font.size(smaller);
              text-decoration: line-through;
              color: color.$background;
              flex-basis: calc(var(--multiplier) * 999);
            }

            .price {
              flex-basis: calc(var(--multiplier) * 999);
            }
          }
        }
      }

      .checkbox {
        display: flex;
        justify-content: flex-end;
        margin: utils.rem(5px) 0;
      }

      .priceDisclaimer {
        font-size: font.size(smaller);
        color: color.$background;
        text-overflow: ellipsis;
        text-align: end;
        white-space: normal;
      }
    }
  }

  .discountAmounts {
    display: flex;
    flex-direction: column;
    padding: size.$half;
    gap: utils.rem(10px);

    .discountAmountItem {
      display: flex;
      width: utils.rem(165px);
      justify-content: space-between;
    }
  }
}
