@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.container {
  @include mixins.border-radius;
  box-shadow: utils.shadow();
  background-color: color.$white;
  width: utils.rem(300px);
  padding: size.$base;
  font-weight: normal;

  .label {
    padding: size.$base 0 size.$quarter;
    color: color.$secondary-dimmed;
    font-size: font.size('small');
    font-weight: bold;

    &:first-child {
      padding-top: 0;
    }
  }

  .status {
    @include mixins.border;
    @include mixins.border-radius('small');
    display: inline-block;
    padding: size.$quarter size.$half;
  }
}
