@use '~/styles/mixins';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/font';
@import '~/components/styles.scss';


.widget {
  @include font.default;
  @include mixins.fill-full;
  padding: size.$base;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-bottom: size.$base;
    margin-bottom: size.$half;
    
    .title {
      font-size: font.size(big);
      @include font-bold;
    }
  }

  main {
    @include widget-scrollable;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: size.$quarter;
  }
}