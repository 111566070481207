@import '~/components/styles.scss';

.option {
  padding: $half-unit 0;
  cursor: pointer;

  &[data-selected='true'] {
    font-weight: bold;
  }
}
