@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.container {
  min-height: utils.rem(150px);
  width: 100%;

  span {
    font-weight: bold;
    font-size: font.size('small');
    color: color.$secondary;
  }

  .wrapper {
    @include mixins.border(2px, color.$secondary);
    @include mixins.border-radius;
    width: auto;

    .emails {
      padding: size.$quarter;
      display: flex;
      flex-wrap: wrap;
      min-height: 32px;

      .emailWrapper {
        display: flex;
        align-items: flex-end;
        @include mixins.border;
        @include mixins.border-radius('small');
        padding: 0 0 size.$quarter size.$quarter;
        margin: size.$quarter;
        max-width: 100%;

        &[data-error='true'] {
          @include mixins.border(1px, color.$error);
        }

        .email {
          margin: 1px 0 0;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .close {
          padding-bottom: utils.rem(2px);
        }
      }

      .inputArea {
        display: flex;
        padding: 0 size.$quarter size.$quarter size.$quarter;
        position: relative;
        min-height: 16px;
        max-width: calc(100% - 10px);

        &[data-placeholder='false'] {
          width: 100%;
        }

        .emailHidden {
          z-index: 2;
          font-weight: 400;
          font-size: font.size('normal');
          visibility: hidden;
        }

        input {
          position: absolute;
          border: 0;
          outline: 0;
          left: 6px;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          font-size: font.size('normal');
          min-width: 10px;

          &:focus {
            outline: none !important;
          }
        }

        .error {
          box-shadow: utils.shadow();
          @include mixins.border-radius('small');
          position: absolute;
          background-color: color.$error;
          color: color.$white;
          margin-top: size.$double;
          margin-left: size.$base;
          padding: size.$half;
          z-index: 3;
        }
      }
    }
  }

  .dropdown {
    z-index: 2;
    @include mixins.w-full;
    box-shadow: utils.shadow();
    @include mixins.border-radius('small');
    background-color: color.$white;
    color: color.$text;
    margin-top: size.$quarter;
    padding: size.$half;

    &[data-valid='true'] {
      display: flex;
      align-items: center;

      img {
        padding: size.$quarter;
      }

      &:hover {
        color: color.$white;
        background-color: color.$secondary;

        img {
          @include mixins.filter('white');
        }
      }
    }
  }

  .addEmailDisplay {
    word-break: break-word;
  }
}
