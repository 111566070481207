@import '~/components/styles.scss';

.input {
  border-radius: $default-border-radius !important;
  width: 100% !important;
  border-color: $cool-grey !important;
}

.container {
  position: relative;

  &.error {
    .icon {
      position: absolute;
      top: $quarter-unit + 1px;
      right: $quarter-unit + 1px;
    }

    .error {
      @include default-shadow;
      @include font-default;
      border-radius: $half-radius;
      position: absolute;
      top: calc(100% + $quarter-unit);
      right: $quarter-unit;
      padding: $half-unit;
      background-color: $color-error;
      color: $color-white;
      z-index: 2;
    }
  }
}

.dropdown > div {
  border-color: $cool-grey !important;
}
