@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';
@use '~/styles/font';

.widget {
  @include mixins.fill-full;
  padding: size.$base;
  margin-right: utils.n(utils.rem(12px));

  .container {
    @include mixins.fill-full;
    padding-right: utils.rem(6px);

    .item {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: font.size();

      &-img {
        width: utils.rem(40px);
        margin-right: size.$base;

        img {
          width: utils.rem(40px);
          height: utils.rem(40px);
        }
      }
    }

    .subtitle {
      font-size: font.size('small');
      color: color.$secondary;
      margin: size.$base 0 size.$half 0;
    }

    .sub-item {
      display: flex;
      align-items: center;
      font-size: font.size();
      margin-bottom: size.$base;

      &-img {
        width: utils.rem(26px);
        margin-right: size.$half;

        img {
          width: utils.rem(26px);
          height: utils.rem(26px);
        }
      }

      .description {
        margin-left: size.$base;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
