@import '~/components/styles.scss';

.content {
  width: 312px;

  .error {
    color: $color-error;
  }

  .password {
    padding-top: $half-unit;

    span {
      font-weight: bold;
      font-size: $small-font-size;
      color: $cool-grey;
    }
  }

  .footer {
    margin-top: $base-unit;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
