@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';

.container {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;

  &[data-overlay='true'] {
    background-color: color.$shadow;
    pointer-events: all;
    z-index: 1;
  }

  .preloader {
    @include mixins.border-radius;
    background-color: color.$secondary;
    color: color.$white;
    padding: size.$base size.$double;
    font-weight: bold;

    &:after {
      content: ' .';
      animation: dots 1s steps(5, end) infinite;
    }

    @keyframes dots {
      0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
      }
      40% {
        color: white;
        text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
      }
      60% {
        text-shadow: .25em 0 0 white, .5em 0 0 rgba(0,0,0,0);
      }
      80%, 100% {
        text-shadow: .25em 0 0 white, .5em 0 0 white;
      }
    }
  }
}
