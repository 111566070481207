@import '~/components/styles.scss';

.manager {
  width: 100vw;
  position: fixed;
  top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}
