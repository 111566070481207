@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.container {
  @include mixins.border-radius;
  box-shadow: utils.shadow();
  background-color: transparent;
  width: utils.rem(128px);
  display: flex;
  gap: size.$half;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  align-items: flex-start;
  z-index: 11;

  .top,
  .bottom {
    @include mixins.border-radius();
    width: 100%;
    background-color: color.$white;
  }

  a,
  .go-to-admin-btn {
    text-decoration: none;
    width: 100%;

    &[data-active-tab='true'] {
      .button {
        color: color.$accent;
        font-weight: bold;
      }

      img {
        filter: invert(46%) sepia(69%) saturate(2044%) hue-rotate(339deg) brightness(92%) contrast(90%);
      }
    }

    .button {
      color: color.$text;
      padding: size.$quarter 0 size.$quarter size.$quarter;
      @include mixins.border-radius;

      img {
        padding-right: size.$quarter;
      }

      &:hover {
        cursor: pointer;
        background-color: color.$background-active;
      }
    }
  }

  .go-to-admin-btn {
    padding: 0;
    border: none;
    text-align: initial;

    img {
      width: 24px;
    }
  }
}
