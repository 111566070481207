@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/color';

.widget {
  box-shadow: utils.shadow();
  background-color: color.$background;
  @include mixins.border-radius;
  pointer-events: auto;

  &[data-active='true'] {
    z-index: 2;
    background-color: color.$background-active;
  }

  &-container {
    @include mixins.border-radius('same');
    @include mixins.h-full;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  &-drag-area {
    @include mixins.center;
    @include mixins.border-radius('same');
    background-color: color.$white;
    cursor: move;

    &-icon {
      width: 1.5rem;
      height: 1rem;
      user-select: none;
    }
  }

  &-content {
    @include mixins.fill-full;
    overflow: hidden;
  }
}
