@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.userTable {
  width: 100%;

  thead {
    position: sticky; 
    top: 0; 
    z-index: 1;
  }

  th {
    text-align: left;
    padding: size.$half 0 size.$quarter size.$base;
    color: color.$secondary;
    background: color.$white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: font.size('small');
    font-weight: bold;
  }

  td {
    padding: size.$half 0 size.$quarter size.$base;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: font.size('normal');

    &[data-role='true'] {
      text-transform: capitalize;
    }
  }
}
