@use 'utils';

$base: 1rem;
$double: 2rem;
$half: 0.5rem;
$base-n-half: 1.5rem;
$quarter: 0.25rem;
$n-base: utils.n($base);
$n-double: utils.n($double);
$n-half: utils.n($half);
$n-quarter: utils.n($quarter);
$catalog-widget-gap: 0.7rem;

$modal-min-width: utils.rem(800px);
$modal-min-sidebar-width: utils.rem(254px);
$modal-min-content-width: calc($modal-min-width - $modal-min-sidebar-width);
$modal-standard-content-height: utils.rem(528px);