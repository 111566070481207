@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.dropdown {
  min-width: utils.rem(131px);
  box-shadow: utils.shadow(
    $blur: (
      utils.rem(10px),
    )
  );

  @include mixins.border-radius;
  margin-right: size.$half;
  background: color.$white;
  display: flex;
  flex-direction: column;

  a {
    @include mixins.border-radius;
    padding: size.$half;
    font-family: Arial, Helvetica, sans-serif;
    font-size: font.size('normal');
    text-decoration: none;
    color: color.$text;

    &:hover {
      cursor: pointer;
      background-color: color.$background-active;
    }
  }
}
