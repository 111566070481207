@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.container {
  @include mixins.border-radius;
  margin-top: size.$half;
  width: utils.rem(154px);
  box-shadow: utils.shadow();
  background-color: color.$white;
  z-index: 11;

  .spacer {
    height: size.$half;
  }

  .item {
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    align-items: flex-start;

    &[data-disabled='false'] {
      &:hover {
        cursor: pointer;
        background-color: color.$background-active;
      }
    }

    &[data-disabled='true'] {
      cursor: default;
      color: color.$disabled;
      pointer-events: none;

      img {
        @include mixins.filter('disabled');
      }
    }

    .button {
      display: flex;
      align-items: center;
      color: color.$text;
      padding: size.$quarter 0 size.$quarter size.$quarter;
      width: 100%;

      img {
        padding-right: size.$quarter;
      }
    }
    .loading {
      color: #95999e;
      position: relative;
      > div::before {
        height: 20px !important;
        width: 20px !important;
        border-width: 3px !important;
      }
    }
  }
}
