@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.header {
  display: flex;

  .title {
    @include mixins.w-full;
    padding: size.$base;
    font-weight: bold;
    font-size: font.size('big');
  }

  .controls {
    @include mixins.center('vertical');

    button:first-child {
      margin-right: size.$half;
    }
  }
}

.main {
  height: size.$modal-standard-content-height;
  padding: size.$base;
}
