@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.main {
  width: size.$modal-min-sidebar-width;
  height: size.$modal-standard-content-height;
  padding-right: size.$quarter;

  .main-container {
    padding: size.$base;

    .title {
      margin: 0;
      font-weight: bold;
      font-size: font.size('big');
    }

    .info-block {
      padding: size.$base 0 size.$quarter;
      @include mixins.phoneField(font.size('normal'));

      .label {
        color: color.$secondary-dimmed;
        font-size: font.size('small');
        font-weight: bold;
        margin-bottom: size.$quarter;
      }

      .address {
        color: color.$text;
        @include mixins.border;
        @include mixins.border-radius;
        display: flex;
        flex-direction: column;
        margin: size.$quarter 0;
        padding: size.$half;
        line-height: size.$base;
        text-align: left;
      }
    }
  }
}
