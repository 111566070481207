@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

.tile {
  @include mixins.border($color: transparent);
  @include mixins.border-radius('small');
  background-color: inherit;
  cursor: pointer;
  width: utils.rem(90px);
  padding: 0;
  user-select: text;

  .image {
    width: utils.rem(90px);
    height: utils.rem(90px);
  }

  .label {
    @include mixins.center('vertical');
    justify-content: center;
    padding: size.$quarter;
    padding-right: 0;
  }

  &:not(:disabled):hover {
    box-shadow: utils.shadow();
  }
}
