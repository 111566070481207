// @import '~/components/styles.scss';
@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';
@use '~/styles/utils';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: size.$base 0 size.$base size.$base;

  .headline {
    text-align: left;
    font-weight: bold;
    text-transform: none;
    font-size: font.size('normal');
    padding-bottom: size.$base;

    div {
      padding-top: size.$quarter;
      color: color.$secondary;
      font-weight: normal;
      font-size: font.size('normal');
    }

    .error {
      font-weight: bold;
      color: color.$error;
    }
  }

  form {
    label {
      display: block;
      font-weight: bold;
      font-size: font.size('small');
      color: color.$secondary;
      padding: size.$half 0 size.$quarter 0;
    }

    .info {
      display: flex;
      flex-direction: row;
      div:first-of-type {
        padding-right: size.$quarter;
      }
    }

    .submitButton {
      padding: size.$base 0;
    }
  }

  .footer {
    font-weight: bold;
    font-size: font.size('normal');
    color: color.$secondary;
    text-align: center;
    align-items: center;

    button {
      margin: size.$half 0;
    }

    .resetPassword {
      padding: size.$half 0 size.$base;

      a {
        color: color.$text;
        text-decoration: none;
        text-align: right;
        font-weight: normal;
        font-size: font.size('normal');

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .bottomlink {
      color: color.$text;
      font-weight: normal;
      font-size: font.size('big');
      min-height: size.$base;
      padding: size.$half;

      a {
        color: color.$text;
        text-decoration: none;

        img {
          padding-bottom: utils.rem(2px);
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .receiver {
    display: none;
  }
}
