@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';
@use '~/styles/utils';
@use '~/styles/mixins';

.modal {
  width: utils.rem(370px);
  height: utils.rem(210px);
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;

  .title {
    text-align: left;
    font-weight: bold;
    text-transform: none;
    font-size: font.size('big');
    margin-bottom: size.$base;
  }

  .section {
    margin-bottom: size.$base;

    .subtitle {
      @include mixins.subtitle;
      margin-bottom: size.$quarter;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    .loader {
      position: relative;
    }
  }
}
