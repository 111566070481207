@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.header {
  display: flex;

  .title {
    @include mixins.w-full;
    padding: size.$base;
    font-weight: bold;
    font-size: font.size('big');
  }

  .controls {
    @include mixins.center('vertical');

    button:first-child {
      margin-right: size.$half;
    }
  }
}

.main {
  height: 'auto';
  padding: size.$base;

  .details {
    display: flex;
    margin-bottom: size.$base;
    gap: size.$base;

    img {
      @include mixins.border;
      @include mixins.border-radius('default');
      @include mixins.w-full;
      max-width: utils.rem(168px);
      height: utils.rem(168px);
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .content-column {
        display: flex;
        flex-direction: column;
        gap: size.$double;

        .empty {
          height: utils.rem(34px);
        }

        .label {
          padding-bottom: size.$quarter;
          color: color.$secondary-dimmed;
          font-size: font.size('small');
          font-weight: bold;
        }
      }
    }
  }
}
