@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

.tile {
  @include mixins.border($color: transparent);
  @include mixins.border-radius('small');
  background-color: inherit;
  cursor: pointer;
  width: utils.rem(144px);
  padding: 0;
  user-select: text;

  .imageWrapper {
    width: utils.rem(144px);
    height: utils.rem(144px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    max-width: 100%;
    height: auto;
  }

  .label {
    @include mixins.center('vertical');
    justify-content: space-between;
    padding-right: 0;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:not(:disabled):hover {
    box-shadow: utils.shadow();
  }

  &[data-active='true'] {
    @include mixins.border($color: color.$accent);
  }

  &:disabled {
    cursor: auto;

    .image {
      opacity: 0.4;
    }
  }
}

.tooltip {
  @include mixins.border-radius('small');
  background-color: color.$white;
  padding: size.$half;
  box-shadow: utils.shadow();
}
