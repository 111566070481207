@import '~/components/styles.scss';

.editor {
  color: $text-grey;
  background-color: $color-white;
  border: 1px solid $cool-grey;
  @include default-border-radius;
  @include font-default;
  padding: $half-unit;
  width: 100%;
  min-height: $base-unit * 4;
  resize: vertical;
  font: inherit;

  &::placeholder {
    color: $cool-grey;
    @include font-default;
  }
}
