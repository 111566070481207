@use '~/styles/color';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.price-icon {
  @include mixins.filter('secondary');
}

.tooltip {
  @include mixins.border-radius('small');
  min-width: utils.rem(40px);
  padding: size.$half;
  background-color: color.$white;
  margin: size.$quarter;
  box-shadow: utils.shadow($blur: size.$quarter);
}
