@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

@keyframes skeleton-animation {
  0% {
    right: 100%;
  }

  50% {
    right: utils.rem(-128px);
  }

  100% {
    right: 100%;
  }
}

.skeleton {
  position: relative;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: row;
  margin-left: size.$base;

  &:before {
    position: absolute;
    width: 50%;
    height: utils.rem(350px);
    background: linear-gradient(90deg, transparent, color.$white, transparent);
    content: '';
    animation: skeleton-animation 2s infinite;
  }

  .title {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    width: 50%;
    height: utils.rem(28px);
    margin-top: size.$half;
    margin-right: 40%;
  }

  .controls {
    display: flex;
    flex-direction: row;
    width: 100%;

    .icon {
      @include mixins.border-radius('small');
      background-color: color.$light-shadow;
      border-radius: 50%;
      height: utils.rem(18px);
      width: utils.rem(48px);
      margin-top: size.$half + size.$quarter;
      margin-right: size.$half;
    }

    .buttons {
      @include mixins.border-radius('small');
      background-color: color.$light-shadow;
      width: 50%;
      height: utils.rem(28px);
      margin-top: size.$half;
      margin-right: size.$half;
    }

    .input {
      @include mixins.border-radius('small');
      background-color: color.$light-shadow;
      @include mixins.w-full;
      height: utils.rem(28px);
      margin-top: size.$half;
    }
  }
}

.listSkeleton {
  position: relative;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0 size.$base;

  &:before {
    position: absolute;
    width: 50%;
    height: utils.rem(630px);
    background: linear-gradient(90deg, transparent, color.$white, transparent);
    content: '';
    animation: skeleton-animation 2s infinite;
  }

  .image {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    @include mixins.w-full;
    height: utils.rem(120px);
    margin: size.$half 0;
  }

  .item {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    @include mixins.w-full;
    height: utils.rem(36px);
    margin-top: size.$half;
  }

  .item:first-child {
    margin-top: size.$quarter;
  }
  .item:last-child {
    margin-bottom: size.$quarter;
  }
}
