@use 'mixins';
@use 'color';
@use 'font';

// rem setup
:root {
  font-size: 16px;
}

html,
body,
#root {
  @include mixins.w-full;
  @include mixins.h-full;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font.default;
  font-size: font.size();
  color: color.$text;
}

button {
  font-size: inherit;
  background-color: transparent;
}
