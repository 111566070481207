@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

@keyframes skeleton-animation {
  0% {
    right: 100%;
  }

  50% {
    right: utils.rem(-128px);
  }

  100% {
    right: 100%;
  }
}

.skeleton {
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 196px;
  padding: size.$base 0 size.$base size.$base;

  &:before {
    position: absolute;
    width: 50%;
    height: utils.rem(350px);
    background: linear-gradient(90deg, transparent, color.$background-active, transparent);
    content: '';
    animation: skeleton-animation 2s infinite;
  }

  .headline {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    width: 70%;
    height: utils.rem(28px);
    margin-top: size.$half;
  }

  .inputTitle {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    width: 20%;
    height: utils.rem(8px);
    margin-top: 2 * size.$base;
  }
  .input {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    @include mixins.w-full;
    height: utils.rem(32px);
    margin-top: size.$quarter;
  }

  .footer {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    @include mixins.w-full;
    height: utils.rem(42px);
    margin-top: size.$half;
  }
}
