@use '~/styles/color';
@use '~/styles/size';

.popup {
  color: color.$text;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: size.$quarter;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow: hidden;

  input {
    background: transparent;
    border: none;
    width: inherit;
  }
}
