.wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  max-width: 100%;

  .content {
    &[data-num-of-visible-arrows='2'] {
      max-width: calc(100% - 36px);
    }

    &[data-num-of-visible-arrows='1'] {
      max-width: calc(100% - 18px);
    }

    &[data-num-of-visible-arrows='0'] {
      max-width: 100%;
    }
  }

  .arrows {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid #979797;
    background-color: rgba($color: #fff, $alpha: 0.8);

    img {
      width: 16px;
      height: 16px;
    }
  }
}

// overflow-y: auto;
// scrollbar-width: none;

// &::-webkit-scrollbar {
//   width: 0;
//   height: 0;
// }
