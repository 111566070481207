@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';

.close {
  height: size.$base;
  padding-right: 0;
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
  }
}
