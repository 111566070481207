@import '~/components/styles.scss';

.notification {
  @include default-shadow;
  @include default-border-radius;
  max-width: 624px;
  width: 100%;
  margin: 0 auto;
  pointer-events: all;
  color: $color-white;
  padding: $half-n-quarter-unit $base-unit;
  margin-bottom: $half-unit;
  display: flex;
  align-items: center;
  animation: fadeIn 100ms ease-in-out;

  img {
    margin-right: $half-unit;
  }

  &.info {
    background-color: $color-info;
  }

  &.success {
    background-color: $color-success;
  }

  &.warning {
    background-color: $color-warning;
  }

  &.error {
    background-color: $color-error;
  }

  &.out {
    animation: fadeOut 300ms ease-out;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
