@use '~/styles/mixins';
@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/color';

.item {
  display: flex;
  align-items: flex-start;
  margin: size.$half 0;
  padding-right: size.$half + size.$quarter;

  .arrow {
    min-width: size.$half + size.$base;
    padding: size.$half;
    padding-left: 0;
    cursor: pointer;

    img {
      width: size.$base;
    }
  }

  &Img {
    min-width: size.$base * 2;
    height: size.$base * 2;
    margin-right: size.$half;

    img {
      width: size.$base * 2;
    }
  }

  .includeItem {
    width: 24px;
    margin-top: -4px;
  }

  &Number {
    margin-right: size.$base;
  }

  &Description {
    flex: 200;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: size.$base;
  }

  &Quantity {
    min-width: 35px;
    margin-left: size.$half + size.$quarter;
    margin-right: size.$quarter;
    text-align: right;
  }
}

.tooltip {
  @include mixins.border-radius('small');
  min-width: utils.rem(40px);
  padding: size.$half;
  background-color: color.$white;
  margin: size.$quarter;
  box-shadow: utils.shadow($blur: size.$quarter);
}
