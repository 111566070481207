@use '../AddCustomerModal/AddCustomerModal.module.scss';
@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';

.fieldValue {
  color: color.$text;
  font-size: font.size('big');
}

.content {
  .row {
    .element_growing {
      display: flex;
      flex-direction: column;

      .text {
        color: color.$text;
        font-weight: normal;
        font-size: font.size('big');
      }

      .fieldValue {
        color: color.$text;
        font-size: font.size('big');
        margin-top: size.$half;
      }
    }
  }
}