@use '~/styles/utils';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/color';

@keyframes skeleton-animation {
  0% {
    right: 100%;
  }

  50% {
    right: utils.rem(-128px);
  }

  100% {
    right: 100%;
  }
}

.skeleton {
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    width: utils.rem(128px);
    height: utils.rem(350px);
    background: linear-gradient(90deg, transparent, color.$background-active, transparent);
    content: '';
    animation: skeleton-animation 2s infinite;
  }

  .line {
    @include mixins.border-radius('small');
    background-color: color.$light-shadow;
    @include mixins.w-full;
    height: utils.rem(28px);
    margin-top: size.$half;
  }

  .group {
    @include mixins.w-full;
    display: grid;
    grid-template-columns: repeat(auto-fill, utils.rem(90px));
    gap: size.$base;
    justify-content: space-between;
    margin-top: size.$base;

    .img {
      @include mixins.border-radius('small');
      background-color: color.$light-shadow;
      width: utils.rem(90px);
      height: utils.rem(90px);
    }

    .label {
      @include mixins.border-radius('small');
      background-color: color.$light-shadow;
      width: utils.rem(90px);
      height: utils.rem(20px);
      margin-top: size.$half;
    }
  }
}
