@import '~/components/styles.scss';

.option {
  cursor: pointer;
  padding: $half-unit;

  &.highlighted {
    background-color: #00000011;
  }

  &:hover {
    background-color: #00000011;
  }
}
