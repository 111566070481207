@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.details {
  width: size.$modal-min-sidebar-width;
  height: size.$modal-standard-content-height;
  padding-right: size.$quarter;

  .details-container {
    padding: size.$base size.$half size.$base size.$base;

    img {
      @include mixins.border;
      @include mixins.border-radius('default');
      @include mixins.w-full;
      max-width: utils.rem(168px);
      height: utils.rem(168px);
    }

    .title {
      padding-top: size.$base;
      font-weight: bold;
      font-size: font.size('big');
    }

    .label {
      padding: size.$base 0 size.$quarter;
      color: color.$secondary-dimmed;
      font-size: font.size('small');
      font-weight: bold;
    }

    .status {
      @include mixins.border;
      @include mixins.border-radius('small');
      display: inline-block;
      margin-top: size.$base;
      padding: size.$quarter size.$half;
    }
  }
}