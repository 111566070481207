@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.item {
  display: flex;
  padding: size.$half size.$base;

  &:hover {
    background-color: color.$background-active;
  }

  &[data-selected='true'] {
    background-color: color.$background-active;

    > img {
      @include mixins.border($color: color.$accent);
    }

    .content {
      .row {
        .title {
          color: color.$accent;
        }

        .label {
          color: color.$text;
        }

        .status {
          @include mixins.border;
          @include mixins.border-radius('small');
          background-color: color.$secondary;
          color: color.$white;
        }
      }
    }
  }

  > img {
    @include mixins.border();
    @include mixins.border-radius('default');
    min-width: utils.rem(72px);
    height: utils.rem(72px);
  }

  .content {
    @include mixins.w-full;
    margin-left: size.$half;

    .row {
      @include mixins.center('vertical');
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: size.$half;
      }

      img {
        height: size.$half + size.$quarter;
      }

      .title {
        font-weight: bold;
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .label {
        color: color.$secondary-dimmed;
        font-size: font.size('small');
        font-weight: bold;
      }

      .reference-number {
        white-space: nowrap;
        margin-right: size.$half;
      }

      .price {
        width: utils.rem(114px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;
      }

      .status {
        @include mixins.border;
        @include mixins.border-radius('small');
        display: inline-block;
        padding: size.$quarter size.$half;
      }
    }
  }
}