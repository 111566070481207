@import '~/components/styles.scss';

.container {
  @include default-border-radius;
  border: 1px solid $cool-grey;
  background-color: $color-white;
  display: flex;
  align-items: center;

  &[data-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }

  input {
    @include font-default;
    flex: 1;
    border: none;
    background-color: transparent;
    height: $base-unit * 2;
    padding: $half-unit;
    min-width: 0;

    &::placeholder {
      @include font-default;
      color: $cool-grey;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    padding-right: $quarter-unit;

    img {
      cursor: pointer;
    }
  }

  &.error {
    .errorIcon {
      margin-left: $quarter-unit;
    }

    .error {
      @include default-shadow;
      @include font-default;
      border-radius: $half-radius;
      position: absolute;
      top: calc(100% + $quarter-unit);
      right: $quarter-unit;
      padding: $half-unit;
      background-color: $color-error;
      color: $color-white;
      z-index: 2;
    }
  }
}

.menu {
  @include default-shadow;
  @include default-border-radius;
  @include widget-scrollable;
  position: absolute;
  background-color: $color-white;
  padding: $quarter-unit 0;
  z-index: 2;
  display: block;
  overflow: auto;

  .empty {
    padding: $base-unit $half-unit;
  }
}
