@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';

.data {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: font.size('small');
  color: color.$secondary;
  text-align: left;
  margin: 0;

  .userData {
    display: flex;
    flex-direction: row;
    padding: size.$base, size.$base, size.$base * 1.5;
    justify-content: space-between;
    border-bottom: solid 1px color.$secondary;

    .info {
      padding: size.$half;
      flex: 1;

      div {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: font.size('big');
        color: color.$text;
        text-align: left;
      }
    }
  }

  .companyData {
    display: flex;
    flex-direction: row;
    padding: size.$base, size.$base, size.$base * 1.5;
    justify-content: space-between;

    .info {
      padding: size.$half;
      flex: 1;

      div {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: font.size('big');
        color: color.$text;
        text-align: left;
      }
    }
  }

  .addressData {
    padding: size.$base, size.$base, size.$base * 1.5;
    justify-content: space-between;

    .info {
      padding: size.$half;
      flex: 1;

      div {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: font.size('big');
        color: color.$text;
        text-align: left;
      }
    }
  }
  .editAddress {
    padding: size.$base;

    .section {
      margin-bottom: size.$half;

      div {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: font.size('big');
        color: color.$text;
        text-align: left;
      }
    }
  }
}
