@use '~/styles/size';
@use '~/styles/color';

.option {
  cursor: pointer;
  padding: size.$quarter;
  margin-right: size.$quarter;

  img {
    margin-right: size.$half;
  }

  &[data-active='true'] {
    font-weight: bold;
  }

  &:hover {
    background-color: color.$light-shadow;
  }
}
