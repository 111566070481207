@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/color';

.container {
  @include mixins.center('vertical');
  justify-content: space-between;
  background-color: color.$secondary;
  color: color.$white;
  padding: size.$half size.$base;
  font-weight: bold;
}
