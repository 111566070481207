@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';

.wrapper {
  margin: size.$n-base;
  min-width: 612px;

  .headline {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid color.$secondary;

    .title {
      display: flex;
      font-size: font.size('big');
      font-weight: bold;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        padding-left: size.$base;
      }
    }
  }

  .userData {
    display: flex;
    font-weight: bold;
    font-size: font.size('small');
    color: color.$secondary;
    padding: size.$base size.$half;
    text-align: left;
    justify-content: space-around;

    .info {
      display: flex;
      flex-direction: column;

      span {
        display: block;
        padding-bottom: size.$half;
      }

      div {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: font.size('big');
        color: color.$text;
        text-align: left;
      }
    }
  }
}
