@use '~/styles/color';
@import '~/components/styles.scss';

.fastener {
  display: flex;
  padding: $half-unit 0;

  &.active{
    background-color: fade-out(color.$secondary, 0.85);
  }

  .radio {
    flex: 0 0 $base-unit + $half-unit;
    height: $base-unit + $half-unit;
    background-image: url('../../../../assets/icons/ico-system-radiobutton-enabled-inactive.svg');
    background-position: center center;
    margin-right: $base-unit;
    cursor: pointer;

    &.active {
      background-image: url('../../../../assets/icons/ico-system-radiobutton-enabled-active.svg');
    }
  }

  .checkbox {
    @extend .radio;
    background-image: url('../../../../assets/icons/ico-system-checkbox-enabled-inactive.svg');

    &.active {
      background-image: url('../../../../assets/icons/ico-system-checkbox-enabled-active.svg');
    }

    &.disabled {
      background-image: url('../../../../assets/icons/ico-system-checkbox-disabled-inactive.svg');
    }
  }

  .group {
    overflow: hidden;
    display: flex;
    flex: 1;

    .item {
      display: flex;
      align-items: center;
      min-width: 0;
      flex: 1;

      &.pointer {
        cursor: pointer;
      }

      &.grouped {
        padding-top: 5px;
      }
    }
  }

  &Img {
    display: grid;
    place-items: center;

    img {
      width: $base-unit + $half-unit;
    }
  }

  &Number {
    margin-left: $quarter-unit;
  }

  &Description {
    margin-left: $base-unit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &Button {
    margin-left: $quarter-unit;
    cursor: pointer;
    width: $base-unit + $half-unit;

    &Flip {
      @extend .fastenerButton;
      background-image: url('../../../../assets/icons/ico-widgets-flip.svg');

      &:hover {
        background-image: url('../../../../assets/icons/ico-widgets-flip.svg');
      }
    }

    &AdjustManualy {
      @extend .fastenerButton;
      background-image: url('../../../../assets/icons/ico-widgets-adjust-manualy-inactive.svg');

      &.active {
        background-image: url('../../../../assets/icons/ico-widgets-adjust-manualy-active.svg');
      }

      &:hover {
        background-image: url('../../../../assets/icons/ico-widgets-adjust-manualy-active.svg');
      }
    }
  }
}
