@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.headline {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px color.$secondary;

  .title {
    @include mixins.border-radius;
    padding: size.$base;
    width: 100%;
    height: auto;
    background-color: color.$white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: font.size('big');

    .email {
      padding-top: size.$quarter;
      color: color.$secondary;
      font-weight: normal;
      font-size: font.size('normal');
    }
    .error {
      padding-top: size.$quarter;
      color: color.$error;
      font-weight: bold;
      font-size: font.size('small');
    }
  }

  .editor {
    @include mixins.border(2px);
    @include mixins.border-radius;
    padding-top: size.$base;
    padding-right: size.$base;
    margin: size.$base;
    width: size.$base * 3;
    height: size.$base * 2.6;

    &:hover {
      cursor: pointer;
    }

    img:first-of-type {
      padding-top: size.$half + calc(size.$quarter / 2);
    }
    .active {
      @include mixins.filter;
    }

    img:nth-of-type(2) {
      position: absolute;
      margin-top: size.$n-base;
      margin-left: size.$n-half;
      width: size.$base * 2;
      height: size.$base * 2;
    }
  }
}
