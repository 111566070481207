@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.header {
  display: flex;

  .title {
    @include mixins.w-full;
    padding: size.$base;
    font-weight: bold;
    font-size: font.size('big');
  }

  .controls {
    display: flex;

    .create-button {
      @include mixins.center('vertical');
      text-decoration: none;
      color: color.$secondary-dimmed;

      img {
        height: size.$base;
        margin-right: size.$half;
      }
    }

    .sorting-container {
      &[data-disabled='true'] {
        pointer-events: none;
      }

      .sorting-button {
        margin: 13px size.$half 13px size.$double;
        cursor: pointer;

        &[data-active='true'] {
          @include mixins.filter;
        }

        &[data-disabled='true'] {
          @include mixins.filter('disabled');
        }
      }
    }

    .search {
      @include mixins.center('vertical');
      width: utils.rem(168px);

      & > div:focus-within {
        outline: 1px solid color.$secondary;
      }

      &[data-empty='false'] img:last-child {
        display: none;
      }

      &[data-disabled='true'] > div {
        @include mixins.border($color: color.$disabled);
        pointer-events: none;

        img {
          @include mixins.filter('disabled');
        }
      }
    }
  }
}

.main {
  width: size.$modal-min-content-width;
  height: size.$modal-standard-content-height;
  display: flex;

  &[data-scrollbar='true'] {
    padding-right: size.$quarter;
  }

  .projects-container {
    overflow: auto;
    flex: 1;
  }
}

.no-projects-container {
  width: size.$modal-min-content-width;
  height: size.$modal-standard-content-height;

  .not-found {
    padding: size.$base;
  }

  .no-projects {
    @include mixins.center;
    width: size.$modal-min-content-width;
    height: size.$modal-standard-content-height;
    flex-direction: column;
    color: color.$secondary-dimmed;
    font-weight: bold;

    img {
      @include mixins.filter('secondary');
      height: 40px;
    }
  }
}

.no-selected {
  padding: size.$base;
  font-weight: bold;
  font-size: font.size('big');
}