@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.wrapper {
  @include mixins.border;
  border-radius: 0 0 4px 4px;

  [role='listbox'] {
    max-height: utils.rem(200px);
    width: 100%;
  }

  .headline {
    display: flex;
    flex-direction: row;
    font-size: font.size('big');
    font-weight: bold;
    justify-content: space-between;
    align-items: center;

    span {
      padding-left: size.$base;
    }
  }

  form {
    padding: size.$half size.$base size.$double size.$base;

    label {
      font-weight: bold;
      font-size: font.size('small');
      color: color.$secondary;
      padding: size.$half 0 size.$quarter 0;
    }

    .inputBlock {
      padding: size.$quarter 0;
      position: relative;
    }
  }
}
