@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.overlay {
  position: fixed;
  @include mixins.stick;
  @include mixins.center;
  background-color: color.$shadow;
  pointer-events: all;

  .window {
    @include mixins.border-radius;
    padding: size.$base;
    background-color: color.$white;
    box-shadow: utils.shadow();

    .modal {
      margin: size.$n-base;
      @include mixins.border-radius;
      position: relative;
      min-width: size.$modal-min-width;

      .buttons {
        margin: size.$base;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div {
          padding: size.$quarter;
        }
      }

      .wrapper {
        margin: size.$base;
        width: utils.rem(44px);
        height: utils.rem(42px);

        .editor {
          @include mixins.border(2px);
          @include mixins.border-radius;
          width: 100%;
          height: 100%;
          padding-top: size.$base;
          padding-right: size.$base;

          &:hover {
            cursor: pointer;
          }

          img:first-of-type {
            padding-top: size.$half + size.$quarter/2;
          }

          .active {
            @include mixins.filter;
          }

          img:nth-of-type(2) {
            position: absolute;
            margin-top: utils.rem(-15px);
            margin-left: utils.rem(-7px);
            width: size.$base * 2;
            height: size.$base * 2;
          }
        }
      }
    }
  }
}
