@use '~/styles/color';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.section {
  margin-bottom: utils.rem(13px);

  &.address-section {
    min-width: calc(190px - size.$base);
  }

  .input-name {
    @include mixins.subtitle;
    margin-bottom: size.$quarter * 1.5;
  }

  [role='listbox'] {
    max-height: utils.rem(376px);
  }

  .status {
    @include mixins.border;
    @include mixins.border-radius('small');
    display: inline-block;
    padding: size.$quarter size.$half;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: size.$half;
    }

    &[data-selected='true'] {
      background-color: color.$secondary;
      color: color.$white;
    }

    &[data-disabled='true'] {
      pointer-events: none;
      background-color: color.$disabled;
      color: color.$white;
    }
  }
}

.add-new {
  color: color.$secondary;
  padding: 0 size.$half size.$quarter;

  div {
    cursor: pointer;

    img {
      padding-right: size.$quarter;
      vertical-align: text-top;
    }
  }
}

.country {
  display: flex;
  gap: size.$base;

  .section {
    width: 100%;
  }
}
