@import '~/components/styles.scss';

.image {
  width: $base-unit + $half-unit;
  height: $base-unit + $half-unit;
  min-width: $base-unit + $half-unit;
  margin-right: $half-unit;
}

.group {
  display: flex;
  align-items: center;
  margin-bottom: $half-unit;

  .open {
    cursor: pointer;
  }
}

.fastener {
  margin-bottom: $quarter-unit;
  display: flex;
  align-items: center;

  .description,
  .itemNumber {
    margin-right: $half-unit;
  }

  .description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}
