
.notesImg {
  img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }

  &[data-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }

  &.selected {
    > * {
      filter: invert(46%) sepia(69%) saturate(2044%) hue-rotate(339deg) brightness(92%) contrast(90%);
    }
  }

  &.add {
    > * {
      filter: invert(68%) sepia(8%) saturate(6%) hue-rotate(329deg) brightness(87%) contrast(88%);
    }
  }
}