@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/size';
@use '~/styles/utils';

.headline {
  display: flex;
  flex-direction: row;  
  padding-right: 0;
}

.modal {
  width: utils.rem(396px);
  height: 100%;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -1rem;
  margin-right: -1rem;

  .title {
    width: 100%;
    text-align: left;
    font-weight: bold;
    text-transform: none;
    font-size: font.size('big');
    margin-bottom: size.$quarter;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .subtitle {
    font-weight: normal;
    font-size: font.size('normal');
    padding-bottom: size.$base;
    margin-right: 1rem;
  }

  .main {
    margin: size.$half 0;
    margin-right: 1rem;
  }
  .saveButton {  
    margin-top: 1rem;
    margin-right: 1rem;
  }

}