@use '~/styles/color';
@use '~/styles/font';
@use '~/styles/mixins';
@use '~/styles/size';
@use '~/styles/utils';

.content,
.container {
  @include mixins.fill-full;
}

.content {
  padding: size.$half;
  padding-bottom: size.$base;

  .container {
    overflow-y: clip;
  }

  header,
  footer {
    @include mixins.center('horizontal');
  }

  header {
    justify-content: start;
    padding-bottom: size.$half;

    .title {
      margin-right: size.$quarter;
      @include mixins.subtitle;
    }

    .icon {
      height: 100%;
      max-height: size.$base-n-half;
    }
  }

  main {
    @include mixins.fill-full;
    overflow-y: hidden;
    margin-right: size.$n-quarter;

    .choice-no,
    .hint {
      padding-top: size.$half;
    }

    .hint {
      @include mixins.subtitle;
    }
  }

  footer {
    justify-content: end;
    padding-top: size.$half;
  }
}

.tooltip {
  max-width: utils.rem(200px);
  box-shadow: utils.shadow();
  padding: size.$half;
  margin: size.$half;
  background-color: color.$white;
  @include mixins.border-radius('small');
  transform: translateX(utils.n(utils.rem(9px)));
  color: color.$text;
}
